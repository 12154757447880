import {
  Col,
  Collapse,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  FormInstance,
} from "antd";
import { useEffect, useState, useCallback } from "react";

import { IBrand } from "../../../services/IBrand";
import { IbrandModelByBrandModelYear } from "../../../services/BrandModel";
import { IVehicleType } from "../../../services/IVehicleType";
import { IDestino } from "../../../services/IDestino";
import { Datepicker } from "../../DatePicker/DatePicker";
import {
  addDays,
  zeroPad,
} from "../../../shared/utils";
import { DEFAULT_FORMAT_DATE } from "../../../shared/MomentJS";
import GraphqlService from "../../../services/graphql/GraphqlService";
import { CustomMessage, EnumsValues } from "../../../shared";
import { IAppSetting } from "../../../services/AppSetting";
import { Query } from "../../../services/graphql/query";
import { IRenovation } from "../../../services/Renovation";
import { formNames } from "../utils/formNames";
import { IProducer } from "../../../services/Producer";
import { IFuel } from "../../../services/Fuel";
import { IBodyWork } from "../../../services/BodyWork";
import { EmissionById } from "../../../services/EmissionById";
import { IQuotation } from "../../../services/IQuotation";
import { IAjustmentRates } from "../../../services/AjustmentRates";
import { indexOf } from "lodash";
import { SelectValue } from "antd/lib/select";
const { Panel } = Collapse;

interface IGeneralDataProps {
  fuelOptions: () => false | JSX.Element[];
  vehicleBrand: IBrand[];
  brandOptions: () => false | JSX.Element[];
  form: FormInstance;
  getBrandModel: (brandSelected: string) => Promise<void>;
  vehicleModelOptions: () => false | JSX.Element[];
  vehicleModel: string[];
  getYearsByBrandAndModel: (
    brandSelected: string,
    modelSelected: string
  ) => Promise<void>;
  yearOptions: () => false | JSX.Element[];
  yearList: string[];
  getBrandModelByBrandModelYear: (
    brandSelected: string,
    modelSelected: string,
    yearSelected: string
  ) => Promise<IbrandModelByBrandModelYear[] | undefined>;
  vehicleVersionOptions: () => JSX.Element[];
  vehicleVersionList: IbrandModelByBrandModelYear[];
  clearOnChangeBrand: () => void;
  clearOnChangeModel: () => void;
  clearOnChangeYear: () => void;
  clearOnChangeVersion: () => void;
  clearOnChangeVehicleType: () => void;
  getVehicleType: (id_liderar: string[]) => Promise<void>;
  vehicleTypeOptions: () => JSX.Element[];
  vehicleTypes: IVehicleType[];
  destinyOptions: (
    vehicleDestinyFiltered: string[] | undefined
  ) => JSX.Element[] | undefined;
  destinos: IDestino[];
  getDestinos: (vehicleTypeIDLiderar: string[] | undefined) => Promise<void>;
  bodyWorkOptions: () => JSX.Element[] | false;
  coveragePeriedOptions: () => false | JSX.Element[];
  coverageTypeOptions: () => false | JSX.Element[];
  renovationData: IRenovation | undefined;
  isRenovation: boolean;
  adjustmentRateOptions: () => false | JSX.Element[];
  adjustmentRates: IAjustmentRates[];
  setAmountOutOfRange: React.Dispatch<React.SetStateAction<boolean>>;
  setAccesoriesError: React.Dispatch<React.SetStateAction<boolean>>;
  amountOutOfRange: boolean;
  accesoriesError: boolean;
  getFuelTypes: (selectedVehicle: IbrandModelByBrandModelYear) => Promise<void>;
  getBodyworkTypes: (
    selectedVehicleIdLiderar?: string,
    selectedVehicleTypeIdLiderar?: string
  ) => Promise<void>;
  idsLiderarForMotorcycles: string[];
  selectedProducer: IProducer | undefined;
  fuelTypes: IFuel[];
  bodyworkTypes: IBodyWork[];
  setBillingCode: React.Dispatch<React.SetStateAction<string>>;
  formType: string;
  emission: EmissionById | undefined;
  quotation: IQuotation | undefined;
  setSelectedVehicleType: React.Dispatch<
    React.SetStateAction<IVehicleType | undefined>
  >;
  setAdditionalHail: React.Dispatch<React.SetStateAction<boolean>>;
  additionalHail: boolean;
  getCoverageTypes: (soloRC?: boolean | undefined) => Promise<void>;
  readOnlyOr: boolean;
  setRenovationBlocked: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IDataRemolque {
  CODPROD: string;
  FECEMI: string;
  MARCA: string;
  ANIO: string;
  // PATENTE: "IVD205",
  TIPOVEH: string;
  COBER: string;
  USO: string;
  LOCALIDAD: string;
  COD_PCIA: string;
}


export const GeneralDataForm = (props: IGeneralDataProps): JSX.Element => {
  const {
    fuelOptions,
    vehicleBrand,
    brandOptions,
    form,
    getBrandModel,
    vehicleModelOptions,
    vehicleModel,
    getYearsByBrandAndModel,
    yearOptions,
    yearList,
    getBrandModelByBrandModelYear,
    vehicleVersionOptions,
    vehicleVersionList,
    clearOnChangeBrand,
    clearOnChangeModel,
    clearOnChangeYear,
    getVehicleType,
    vehicleTypeOptions,
    vehicleTypes,
    destinyOptions,
    destinos,
    clearOnChangeVersion,
    clearOnChangeVehicleType,
    getDestinos,
    bodyWorkOptions,
    coveragePeriedOptions,
    coverageTypeOptions,
    renovationData,
    isRenovation,
    adjustmentRateOptions,
    adjustmentRates,
    setAmountOutOfRange,
    setAccesoriesError,
    getBodyworkTypes,
    getFuelTypes,
    idsLiderarForMotorcycles,
    selectedProducer,
    fuelTypes,
    bodyworkTypes,
    setBillingCode,
    setSelectedVehicleType,
    setAdditionalHail,
    getCoverageTypes,
    readOnlyOr,
    setRenovationBlocked,
  } = props;
  
  const {
    ADDITIONAL_HAIL,
    ADJUSTMENT_RATE,
    BODYWORK_TYPE,
    COVERAGE,
    COVERAGE_TIME,
    DESTINY,
    FUEL_TYPE,
    INSURED_AMOUNT,
    PRODUCT_CODE,
    START_DATE,
    TOTAL_ACCESSORIES,
    VEHICLE_BRAND,
    VEHICLE_MODEL,
    VEHICLE_TYPE,
    VEHICLE_VERSION,
    VEHICLE_YEAR,
    title,
  } = formNames.generalData;

  const {
    ACCESSORIES_CUPULA,
    ACCESSORIES_FREEZER,
    ACCESSORIES_GNC,
    ACCESSORIES_SPECIAL_RIM,
    title: titleAccessories,
  } = formNames.generalDataAccessories;

  const { QUOTATION_RESULT_MESSAGE } = formNames.quote;

  const [vehicleDestinyFiltered, setVehicleDestinyFiltered] =
    useState<string[]>();
  const [amountInsured, setAmountInsured] = useState<number>(0);
  const [tires, setTires] = useState<number>(0);
  const [gnc, setGnc] = useState<number>(0);
  const [freezer, setFreezer] = useState<number>(0);
  const [cupula, setCupula] = useState<number>(0);
  const [amountInsuredModified, setAmountInsuredModified] = useState<number>(0);
  const { customRequest } = GraphqlService();
  const { messageError, messageModalError } = CustomMessage();
  const [totalAccessoriesLimit, setTotalAccessoriesLimit] =
    useState<IAppSetting>();
  const [invalidVehicleTypeInput, setInvalidVehicleTypeInput] =
    useState<boolean>(false);
  const [invalidBodyworkTypeInput, setInvalidBodyworkTypeInput] =
    useState<boolean>(false);
  const [invalidFuelTypeInput, setInvalidFuelTypeInput] =
    useState<boolean>(false);
  const [invalidDestinyTypeInput, setInvalidDestinyTypeInput] =
    useState<boolean>(false);
  const [vehicleSelected, setVehicleSelected] =
    useState<IbrandModelByBrandModelYear>();
  const [isRc, setIsRc] = useState<boolean>(false);

  const [dataForRemolque, setDataForRemolque] = useState<IDataRemolque>({ANIO:"", COBER:"",COD_PCIA:"",CODPROD:"",FECEMI:"",LOCALIDAD:"",MARCA:"",TIPOVEH:"",USO:""});

 

  //Precarga de datos del vehiculo

  const errorMessageRenovacion =
    "Debe enviar la Renovación a Casa Central/Agencia";

  const MensajeRenovacion = () => {
    messageModalError({
      context: "renovation - generalDataForm.1",
      message: errorMessageRenovacion,
    });
  };


  
  
  useEffect(() => {
    if (renovationData) {
      handleAdditionalHail(
        renovationData?.policy?.vehiculo[0]?.adicional_granizo === "S"
      );
    }
  }, [renovationData]);

  const handleAdditionalHail = (value: boolean) => {
    form.setFieldsValue({ [ADDITIONAL_HAIL.name]: value });
    setAdditionalHail(value);
  };

  

  const checkIfRenovationNeedToBeBlocked = (
    amount_insured: string,
    cobertura_codigo: string
  ) => {
    if (!amount_insured || !cobertura_codigo) return;
    if (
      amount_insured === EnumsValues.ConstStrings.zero &&
      cobertura_codigo !== EnumsValues.coverageType.A
    ) {
      setRenovationBlocked(true);
      MensajeRenovacion();
      form.setFieldsValue({
        [QUOTATION_RESULT_MESSAGE.name]: errorMessageRenovacion,
      });
    } else {
      setRenovationBlocked(false);
      form.setFieldsValue({
        [QUOTATION_RESULT_MESSAGE.name]: undefined,
      });
    }
  };

  useEffect(() => {
    if (renovationData && vehicleBrand.length) {
      handleChangeVehicleBrand(renovationData.brand_model.brand);
      form.setFieldsValue({
        [VEHICLE_BRAND.name]: renovationData.brand_model.brand,
      });
      setDataForRemolque({...dataForRemolque, MARCA:renovationData.brand_model.brand})
    }
  }, [renovationData, vehicleBrand]);

  useEffect(() => {
    if (renovationData && vehicleModel.length) {
      handleChangeVehicleModelSelect(renovationData.brand_model.vehicleModel);
      form.setFieldsValue({
        [VEHICLE_MODEL.name]: renovationData.brand_model.vehicleModel,
      });
    }
  }, [renovationData, vehicleModel]);

  useEffect(() => {
    if (renovationData && yearList.length) {
      handleChangeVehicleYear(renovationData.brand_model.year);
      form.setFieldsValue({
        [VEHICLE_YEAR.name]: renovationData.brand_model.year,
      });
    }
  }, [renovationData, yearList]);

  useEffect(() => {
    if (renovationData && vehicleVersionList.length) {
      handleChangeVersion(renovationData.brand_model.model);
      form.setFieldsValue({
        [VEHICLE_VERSION.name]: renovationData.brand_model.model,
      });
    }
  }, [renovationData, vehicleVersionList]);

  useEffect(() => {
    if (renovationData && renovationData.policy && vehicleTypes.length) {
      handleChangeVehicleType(renovationData.policy.vehiculo[0].tipo_codigo);
      form.setFieldsValue({
        [VEHICLE_TYPE.name]: renovationData.policy.vehiculo[0].tipo_codigo,
      });
    }
  }, [renovationData, vehicleTypes]);

  useEffect(() => {
    if (renovationData && renovationData.policy && destinos.length) {
      const selectedDestiny = renovationData.policy.vehiculo[0].uso_codigo;

      const isValid = destinos.find(
        (destino) => destino.id_liderar === selectedDestiny
      );
      if (!isValid) {
        form.setFieldsValue({
          [DESTINY.name]: undefined,
        });
        setInvalidDestinyTypeInput(true);
        return;
      }

      form.setFieldsValue({
        [DESTINY.name]: selectedDestiny,
      });
    }
  }, [renovationData, destinos]);

  useEffect(() => {
    if (renovationData && renovationData.policy && fuelTypes.length) {
      const selectedFuelType = zeroPad(
        Number(renovationData.policy.vehiculo[0].tipo_combustible_codigo),
        2
      );

      const isValid = fuelTypes.find(
        (fuelType) => fuelType.id_liderar === selectedFuelType
      );
      if (!isValid) {
        form.setFieldsValue({
          [FUEL_TYPE.name]: undefined,
        });
        setInvalidFuelTypeInput(true);
        return;
      }

      form.setFieldsValue({
        [FUEL_TYPE.name]: selectedFuelType,
      });
    }
  }, [renovationData, fuelTypes, form]);

  useEffect(() => {
    if (renovationData && renovationData.policy && bodyworkTypes.length) {
      const bodyWorkSelected =
        renovationData.policy.vehiculo[0].codigo_carroceria;

      const isValid = bodyworkTypes.find(
        (bodyworkType) => bodyworkType.id_liderar === bodyWorkSelected
      );
      if (!isValid) {
        form.setFieldsValue({
          [BODYWORK_TYPE.name]: undefined,
        });
        setInvalidBodyworkTypeInput(true);
        return;
      }

      form.setFieldsValue({
        [BODYWORK_TYPE.name]: bodyWorkSelected,
      });
    }
  }, [renovationData, bodyworkTypes, form]);

  useEffect(() => {
    if (
      renovationData &&
      renovationData.policy &&
      adjustmentRates.length &&
      vehicleSelected
    ) {
      form.setFieldsValue({
        [ADJUSTMENT_RATE.name]:
          renovationData?.policy.vehiculo[0]?.ajuste_automotor.slice(1) ||
          EnumsValues.adjustmentRate.zeroZero,
      });
    }
  }, [renovationData, adjustmentRates, vehicleSelected]);

  useEffect(() => {
    if (renovationData && renovationData.policy && vehicleSelected) {
      const accesories_codes =
        renovationData?.policy.vehiculo[0]?.codigo_accesorios;
      const accesories_values =
        renovationData?.policy.vehiculo[0]?.suma_accesorios;

      const cupulaValue =
        accesories_values[
          indexOf(accesories_codes, EnumsValues.accessoryIdLiderar.cupula)
        ]?.split(".")[0];
      const freezerValue =
        accesories_values[
          indexOf(accesories_codes, EnumsValues.accessoryIdLiderar.freezer)
        ]?.split(".")[0];
      const gncValue =
        accesories_values[
          indexOf(accesories_codes, EnumsValues.accessoryIdLiderar.gnc)
        ]?.split(".")[0];
      const tiresValue =
        accesories_values[
          indexOf(accesories_codes, EnumsValues.accessoryIdLiderar.tires)
        ]?.split(".")[0];

      form.setFieldsValue({
        [INSURED_AMOUNT.name]: renovationData?.brand_model.amount_insured,
        [ACCESSORIES_CUPULA.name]: cupulaValue,
        [ACCESSORIES_FREEZER.name]: freezerValue,
        [ACCESSORIES_GNC.name]: gncValue,
        [ACCESSORIES_SPECIAL_RIM.name]: tiresValue,
      });

      setAmountInsured(Number(renovationData?.brand_model.amount_insured || 0));

      if (
        renovationData?.brand_model.amount_insured &&
        renovationData.policy.vehiculo[0].cobertura_codigo.slice(-2)
      ) {
        checkIfRenovationNeedToBeBlocked(
          renovationData?.brand_model.amount_insured,
          renovationData.policy.vehiculo[0].cobertura_codigo.slice(-2)
        );
      }

      setCupula(Number(cupulaValue || 0));
      setFreezer(Number(freezerValue || 0));
      setGnc(Number(gncValue || 0));
      setTires(Number(tiresValue || 0));
    }
  }, [renovationData, vehicleSelected]);

  const handleChangeVehicleBrand = async (selectedBrand: any) => {
    form.setFieldsValue({
      [VEHICLE_MODEL.name]: undefined,
      [VEHICLE_YEAR.name]: undefined,
      [VEHICLE_VERSION.name]: undefined,
      [VEHICLE_TYPE.name]: undefined,
      [DESTINY.name]: undefined,
      [INSURED_AMOUNT.name]: undefined,
      REMOLQUE_CC: undefined
    });
    clearOnChangeBrand();
    await getBrandModel(selectedBrand);
  };

  const handleChangeVehicleModelSelect = async (selectedModel: string) => {
    form.setFieldsValue({
      [VEHICLE_YEAR.name]: undefined,
      [VEHICLE_VERSION.name]: undefined,
      [VEHICLE_TYPE.name]: undefined,
      [DESTINY.name]: undefined,
      [INSURED_AMOUNT.name]: undefined,
      REMOLQUE_CC: undefined
    });
    clearOnChangeModel();
    await getYearsByBrandAndModel(
      form.getFieldValue([VEHICLE_BRAND.name]),
      selectedModel
    );
  };

  const handleChangeVehicleYear = async (yearSelected: string) => {
    form.setFieldsValue({
      [VEHICLE_VERSION.name]: undefined,
      [VEHICLE_TYPE.name]: undefined,
      [DESTINY.name]: undefined,
      [INSURED_AMOUNT.name]: undefined,
      REMOLQUE_CC: undefined
    });
    clearOnChangeYear();
    await getBrandModelByBrandModelYear(
      form.getFieldValue([VEHICLE_BRAND.name]),
      form.getFieldValue([VEHICLE_MODEL.name]),
      yearSelected
    );
  };

  const setTypeOfVehicle = (selectedVehicles: IbrandModelByBrandModelYear) => {
    const indexYearFilter: number | undefined =
      selectedVehicles.valid_years_array.findIndex(
        (year) => year === form.getFieldValue([VEHICLE_YEAR.name])
      );

    if (indexYearFilter !== undefined) {
      const amountIn: string | undefined =
        selectedVehicles.amount_insured_array[indexYearFilter];

      if (amountIn === "0") {
        form.setFieldsValue({
          [ADJUSTMENT_RATE.name]: EnumsValues.adjustmentRate.zeroZero,
          [ACCESSORIES_CUPULA.name]: 0,
          [ACCESSORIES_FREEZER.name]: 0,
          [ACCESSORIES_GNC.name]: 0,
          [ACCESSORIES_SPECIAL_RIM.name]: 0,
          [TOTAL_ACCESSORIES.name]: "0",
          [COVERAGE.name]: undefined,
          [INSURED_AMOUNT.name]: "0",
        });
        setAccesoriesError(false);
        setTires(0);
        setGnc(0);
        setFreezer(0);
        setCupula(0);
        getCoverageTypes(true);
        setAmountInsured(0);
      }

      if (amountIn && amountIn !== "0") {
        form.setFieldsValue({
          [INSURED_AMOUNT.name]: amountIn,
          [COVERAGE.name]: undefined,
        });

        setAmountInsured(Number(amountIn));
        setAmountInsuredModified(Number(amountIn));
        setAmountOutOfRange(false);
      }
      getVehicleType(selectedVehicles?.vehicle_type_array);
      getCoverageTypes();
    }
  };

  const handleChangeVersion = (vehicleVersionSelected: string) => {
    form.setFieldsValue({
      [VEHICLE_TYPE.name]: undefined,
      [DESTINY.name]: undefined,
    });
    clearOnChangeVersion();
    const selectedVehicles = vehicleVersionList?.find(
      (vehicle: IbrandModelByBrandModelYear) =>
        vehicle.model === vehicleVersionSelected
    );
    if (selectedVehicles) {
      setVehicleSelected(selectedVehicles);
      getFuelTypes(selectedVehicles);
      setTypeOfVehicle(selectedVehicles);
      form.setFieldsValue({
        [FUEL_TYPE.name]: undefined,
      });
    }
  };

  const getProductCode = useCallback(
    (vehicleTypeIdLiderar: string): void => {
      if (selectedProducer && idsLiderarForMotorcycles) {
        if (idsLiderarForMotorcycles.includes(vehicleTypeIdLiderar)) {
          form.setFieldsValue({
            [PRODUCT_CODE.name]: selectedProducer.cod_prod_moto,
          });
        } else {
          form.setFieldsValue({
            [PRODUCT_CODE.name]: selectedProducer.cod_prod_auto,
          });
        }
      }
    },
    [selectedProducer, idsLiderarForMotorcycles, form]
  );

  useEffect(() => {
    if (renovationData && vehicleTypes.length) {
      const selectedVehicleType = form.getFieldValue(VEHICLE_TYPE.name);

      const isValid = vehicleTypes.find(
        (vehicleType) => vehicleType.id_liderar === selectedVehicleType
      );
      if (!isValid) {
        form.setFieldsValue({
          [VEHICLE_TYPE.name]: undefined,
        });
        setInvalidVehicleTypeInput(true);
        return;
      }

      getProductCode(selectedVehicleType);
    }
  }, [renovationData, vehicleTypes]);

  useEffect(() => {
    if (
      vehicleSelected &&
      selectedProducer &&
      idsLiderarForMotorcycles.length &&
      renovationData?.policy
    ) {
      getProductCode(renovationData.policy.vehiculo[0].tipo_codigo);
    }
  }, [
    vehicleSelected,
    selectedProducer,
    idsLiderarForMotorcycles,
    renovationData,
  ]);

  useEffect(() => {
    if (renovationData && renovationData.policy && vehicleSelected) {
      form.setFieldsValue({
        [COVERAGE.name]:
          renovationData.policy.vehiculo[0].cobertura_codigo.slice(-2),
      });
    }
  }, [renovationData, vehicleSelected]);

  const handleChangeVehicleType = async (vehicle_type_id_liderar: string) => {
    form.setFieldsValue({
      [DESTINY.name]: undefined,
      REMOLQUE_CC: undefined
    });
    clearOnChangeVehicleType();
    const vehicleLiderar: IVehicleType[] = vehicleTypes.filter(
      (vehicleType) => vehicleType.id_liderar === vehicle_type_id_liderar
    );

    if (vehicleLiderar.length) {
      setBillingCode(vehicleLiderar[0].codigo_tarifacion);
      getDestinos(vehicleLiderar[0].destiny_array);
      setVehicleDestinyFiltered(vehicleLiderar[0].destiny_array);
      setSelectedVehicleType(vehicleLiderar[0]);
    }

    if (vehicleSelected) {
      getProductCode(vehicle_type_id_liderar);
      getBodyworkTypes(
        vehicleSelected?.bodywork_type_default_id_liderar,
        vehicle_type_id_liderar
      );
      form.setFieldsValue({
        [BODYWORK_TYPE.name]: undefined,
      });
    }
  };

  const getAmountInsuredVariation = useCallback(async () => {
    try {
      const data = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingKeys.maxAmountAccessories },
        },
      });
      setTotalAccessoriesLimit(() => data);
    } catch (error: any) {
      messageError({
        context: "getAmountInsuredVariation",
        message: error?.message,
      });
    }

    form.setFieldsValue({
      [ADJUSTMENT_RATE.name]: EnumsValues.adjustmentRate.zeroZero,
    });

  }, []);
  
  useEffect(() => {
    getAmountInsuredVariation();
  }, []);

  useEffect(() => {
    const total = cupula + gnc + freezer + tires;

    form.setFieldsValue({
      [TOTAL_ACCESSORIES.name]: total,
    });

    const amountInsuredToCheckLimit = amountInsuredModified || amountInsured;

    const accesoriesLimit =
      (amountInsuredToCheckLimit *
        Number(totalAccessoriesLimit?.setting_value)) /
      100;

    if (total > accesoriesLimit) {
      setAccesoriesError(true);
    } else {
      setAccesoriesError(false);
    }
  }, [
    cupula,
    gnc,
    freezer,
    tires,
    form,
    TOTAL_ACCESSORIES.name,
    amountInsuredModified,
    amountInsured,
    totalAccessoriesLimit?.setting_value,
  ]);

  
  const cleanAdittionalOnCoverageRC = (value: SelectValue) => {
    if (EnumsValues.coverageType.A === value) {
      setIsRc(true);
      form.setFieldsValue({
        [ACCESSORIES_CUPULA.name]: 0,
        [ACCESSORIES_FREEZER.name]: 0,
        [ACCESSORIES_GNC.name]: 0,
        [ACCESSORIES_SPECIAL_RIM.name]: 0,
        [TOTAL_ACCESSORIES.name]: "0",
        [ADDITIONAL_HAIL.name]: false,
      });
      setTires(0);
      setGnc(0);
      setFreezer(0);
      setCupula(0);
      setAdditionalHail(false);
    } else {
      setIsRc(false);
    }
  };

  return (
    <>
      <Collapse defaultActiveKey={["1"]} className="collapse">
        <Panel header={title} key="1">
          <Row justify="space-between" gutter={40}>
            <Col xs={24} md={12} xl={6}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Seleccione marca del vehículo",
                  },
                ]}
                label={VEHICLE_BRAND.label}
                name={VEHICLE_BRAND.name}
              >
                <Select
                  showSearch
                  placeholder={VEHICLE_BRAND.placeholder}
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  onSelect={handleChangeVehicleBrand}
                  disabled={isRenovation || readOnlyOr}
                  className={readOnlyOr ? "read-only" : undefined}
                >
                  {brandOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12} xl={6}>
              <Form.Item
                rules={[
                  {
                    required: !isRenovation,
                    message: "Seleccione modelo del vehículo",
                  },
                ]}
                label={VEHICLE_MODEL.label}
                name={VEHICLE_MODEL.name}
              >
                <Select
                  showSearch
                  placeholder={VEHICLE_MODEL.placeholder}
                  onSelect={handleChangeVehicleModelSelect}
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  dropdownMatchSelectWidth={false}
                  disabled={
                    vehicleModel.length === 0 || isRenovation || readOnlyOr
                  }
                  className={readOnlyOr ? "read-only" : undefined}
                >
                  {vehicleModelOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12} xl={6}>
              <Form.Item
                rules={[{ required: true, message: "Seleccione año" }]}
                label={VEHICLE_YEAR.label}
                name={VEHICLE_YEAR.name}
              >
                <Select
                  placeholder={VEHICLE_YEAR.placeholder}
                  onSelect={handleChangeVehicleYear}
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  dropdownMatchSelectWidth={false}
                  disabled={yearList.length === 0 || isRenovation || readOnlyOr}
                  className={readOnlyOr ? "read-only" : undefined}
                >
                  {yearOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12} xl={6}>
              <Form.Item
                rules={[{ required: true, message: "Seleccione versión" }]}
                label={VEHICLE_VERSION.label}
                name={VEHICLE_VERSION.name}
              >
                <Select
                  placeholder={VEHICLE_VERSION.placeholder}
                  onSelect={handleChangeVersion}
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  dropdownMatchSelectWidth={false}
                  disabled={
                    vehicleVersionList.length === 0 ||
                    isRenovation ||
                    readOnlyOr
                  }
                  className={readOnlyOr ? "read-only" : undefined}
                >
                  {vehicleVersionOptions()}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" gutter={40}>
            <Col xs={24} md={12} xl={6}>
              <Form.Item
                rules={[
                  { required: true, message: "Seleccione tipo de vehículo" },
                ]}
                label={VEHICLE_TYPE.label}
                name={VEHICLE_TYPE.name}
              >
                <Select
                  placeholder={VEHICLE_TYPE.placeholder}
                  onSelect={handleChangeVehicleType}
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  dropdownMatchSelectWidth={false}
                  disabled={
                    vehicleTypes.length === 0 ||
                    (isRenovation && !invalidVehicleTypeInput) ||
                    readOnlyOr
                  }
                  className={readOnlyOr ? "read-only" : undefined}
                >
                  {vehicleTypeOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12} xl={6}>
              <Form.Item
                label={DESTINY.label}
                name={DESTINY.name}
                rules={[
                  {
                    required: true,
                    message: "Seleccione destino del vehículo",
                  },
                ]}
              >
                <Select
                  placeholder={DESTINY.placeholder}
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  dropdownMatchSelectWidth={false}
                  disabled={
                    destinos.length === 0 ||
                    (isRenovation && !invalidDestinyTypeInput) ||
                    readOnlyOr
                  }
                  className={readOnlyOr ? "read-only" : undefined}
                >
                  {destinyOptions(vehicleDestinyFiltered)}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12} xl={6}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Seleccione tipo de combustible",
                  },
                ]}
                label={FUEL_TYPE.label}
                name={FUEL_TYPE.name}
              >
                <Select
                  placeholder={FUEL_TYPE.placeholder}
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  dropdownMatchSelectWidth={false}
                  disabled={
                    (isRenovation && !invalidFuelTypeInput) || readOnlyOr
                  }
                  className={readOnlyOr ? "read-only" : undefined}
                >
                  {fuelOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12} xl={6}>
              <Form.Item
                rules={[
                  { required: true, message: "Ingrese tipo de carrocería" },
                ]}
                label={BODYWORK_TYPE.label}
                name={BODYWORK_TYPE.name}
              >
                <Select
                  placeholder={BODYWORK_TYPE.placeholder}
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  dropdownMatchSelectWidth={false}
                  disabled={
                    (isRenovation && !invalidBodyworkTypeInput) || readOnlyOr
                  }
                  className={readOnlyOr ? "read-only" : undefined}
                >
                  {bodyWorkOptions()}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" gutter={40}>
            <Col xs={24} md={12} xl={6}>
              <Form.Item
                rules={[
                  { required: true, message: "Seleccione tipo de cobertura" },
                ]}
                label={COVERAGE.label}
                name={COVERAGE.name}
              >
                <Select
                  placeholder={COVERAGE.placeholder}
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  dropdownMatchSelectWidth={false}
                  disabled={readOnlyOr}
                  className={readOnlyOr ? "read-only" : undefined}
                  onChange={(value) => {
                    cleanAdittionalOnCoverageRC(value);
                    if (renovationData?.brand_model.amount_insured && value) {
                      checkIfRenovationNeedToBeBlocked(
                        renovationData?.brand_model.amount_insured,
                        String(value)
                      );
                    }
                  }}
                >
                  {coverageTypeOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12} xl={6}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Seleccione fecha de inicio",
                  },
                ]}
                label={START_DATE.label}
                name={START_DATE.name}
              >
                <Datepicker
                  name={START_DATE.placeholder}
                  format={DEFAULT_FORMAT_DATE}
                  allowClear
                  minDate={new Date()}
                  maxDate={addDays(new Date(), 30)}
                  getPopupContainer={(trigger) => trigger}
                  showToday
                  disabled={isRenovation || readOnlyOr}
                  className={readOnlyOr ? "read-only" : undefined}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} xl={6}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Seleccione periodo de cobertura",
                  },
                ]}
                label={COVERAGE_TIME.label}
                name={COVERAGE_TIME.name}
              >
                <Select
                  placeholder={COVERAGE_TIME.placeholder}
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  dropdownMatchSelectWidth={false}
                  disabled={readOnlyOr}
                  className={readOnlyOr ? "read-only" : undefined}
                >
                  {coveragePeriedOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12} xl={6}>
              <Form.Item
                rules={[
                  { required: true, message: "Seleccione tasa de ajuste" },
                ]}
                label={ADJUSTMENT_RATE.label}
                name={ADJUSTMENT_RATE.name}
                style={{display:"none"}}
              >
                <Select
                  placeholder={ADJUSTMENT_RATE.placeholder}
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  dropdownMatchSelectWidth={false}
                  disabled={readOnlyOr || amountInsured === 0}
                  className={readOnlyOr ? "read-only" : undefined}
                >
                  {adjustmentRateOptions()}
                </Select>
              </Form.Item>

              <Form.Item
                rules={[
                  { required: true, message: "Ingrese código de producto" },
                ]}
                label={PRODUCT_CODE.label}
                name={PRODUCT_CODE.name}
              >
                <Input
                  type="text"
                  placeholder={PRODUCT_CODE.placeholder}
                  disabled
                  readOnly
                  className={readOnlyOr ? "read-only" : undefined}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" gutter={40}>
          <Col xs={24} md={12} xl={6}>
              
            </Col>
            <Col xs={24} md={12} xl={6}>
              {/* <Form.Item
                className="label-space"
                rules={[
                  {
                    required: true,
                    message: "Ingrese suma asegurada",
                  },
                  {
                    pattern: /^[\d]{0,20}$/,
                    message: "Máximo 20 dígitos",
                  },
                ]}
                label={
                  <div className="space-between">
                    <span>Suma asegurada</span>
                    {amountOutOfRange ? (
                      <Tag className="tagAmountOutOfRange" color="error">
                        INVÁLIDO
                      </Tag>
                    ) : (
                      ""
                    )}
                  </div>
                }
                name={INSURED_AMOUNT.name}
              >
                <InputNumber
                  type="text"
                  placeholder={INSURED_AMOUNT.placeholder}
                  onChange={handleChangeAmountInsured}
                  disabled={readOnlyOr || amountInsured === 0}
                  className={readOnlyOr ? "read-only" : undefined}
                />
              </Form.Item> */}
            </Col>
            <Col xs={24} md={12} xl={6}>
              {/* <Form.Item
                className="label-space"
                label={
                  <div className="space-between">
                    <span>Total accesorios:</span>
                    {accesoriesError ? (
                      <Tag className="tagAmountOutOfRange" color="error">
                        EXCEDIDO
                      </Tag>
                    ) : (
                      ""
                    )}
                  </div>
                }
                name={TOTAL_ACCESSORIES.name}
              >
                <Input
                  placeholder={TOTAL_ACCESSORIES.placeholder}
                  readOnly
                  disabled
                  className={readOnlyOr ? "read-only" : undefined}
                />
              </Form.Item> */}
            </Col>
            
            <Col xs={24} md={12} xl={6}>
            {/* <Form.Item 
                  rules={[
                    {
                      required: true,
                      message: "Seleccione servicio de remolque",
                    },
                  ]}
                  label="Servicio de Remolque" name="REMOLQUE_CC">
                {renderRemolqueComponent()}
                <span style={{fontSize: 10, paddingLeft: 6}}>(* Requiere completar antes datos del asegurado)</span>
                <button style={{fontSize: 10, paddingLeft: 6}} type="button" onClick={() =>checkIfCanCallTowingServices()}>Refrescar</button>
              </Form.Item> */}
            </Col>
          </Row>
          {/* <Row>
            <Col xs={24} md={12} xl={6}>
              <Form.Item
                label={ADDITIONAL_HAIL.label}
                name={ADDITIONAL_HAIL.name}
              >
                {formType !== EnumsValues.formType.directEmissionReadOnly &&
                  formType !==
                    EnumsValues.formType.directEmissionHistoricalReadOnly &&
                  formType !==
                    EnumsValues.formType.renowedQuotesListReadOnly && (
                    <Switch
                      onChange={handleAdditionalHail}
                      checked={additionalHail}
                      disabled={isRc}
                    />
                  )}
                {readOnlyOr && (
                  <Switch
                    checked={
                      emission?.additional_hail === "S" ||
                      quotation?.quotation_request.additional_hail === "S"
                    }
                    disabled={readOnlyOr}
                  />
                )}
              </Form.Item>
            </Col>
          </Row> */}
        </Panel>
      </Collapse>
      <Collapse style={{display:"none"}} defaultActiveKey={["2"]} className="collapse">
        <Panel header={titleAccessories} key="1">
          <Row gutter={40} className="accessories-row">
            <Col xs={24} md={12} xl={6}>
              <Form.Item
                className="accessories-item"
                label={ACCESSORIES_CUPULA.label}
                name={ACCESSORIES_CUPULA.name}
                rules={[
                  {
                    pattern: /^[\d]{0,10}$/,
                    message: "Máximo 10 dígitos",
                  },
                ]}
              >
                <InputNumber
                  aria-label="Accesorios del vehiculo"
                  placeholder={ACCESSORIES_CUPULA.placeholder}
                  value={cupula}
                  onChange={setCupula}
                  disabled={readOnlyOr || amountInsured === 0 || isRc}
                  className={readOnlyOr ? "read-only" : undefined}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} xl={6}>
              <Form.Item
                className="accessories-item"
                label={ACCESSORIES_FREEZER.label}
                name={ACCESSORIES_FREEZER.name}
                rules={[
                  {
                    pattern: /^[\d]{0,10}$/,
                    message: "Máximo 10 dígitos",
                  },
                ]}
              >
                <InputNumber
                  aria-label="Accesorios del vehiculo"
                  placeholder={ACCESSORIES_FREEZER.placeholder}
                  value={freezer}
                  onChange={setFreezer}
                  disabled={
                    !form.getFieldValue("vehicle_version") ||
                    readOnlyOr ||
                    amountInsured === 0 ||
                    isRc
                  }
                  className={readOnlyOr ? "read-only" : undefined}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} xl={6}>
              <Form.Item
                className="accessories-item"
                label={ACCESSORIES_GNC.label}
                name={ACCESSORIES_GNC.name}
                rules={[
                  {
                    pattern: /^[\d]{0,10}$/,
                    message: "Máximo 10 dígitos",
                  },
                ]}
              >
                <InputNumber
                  aria-label="Accesorios del vehiculo"
                  placeholder={ACCESSORIES_GNC.placeholder}
                  value={gnc}
                  onChange={setGnc}
                  disabled={
                    !form.getFieldValue("vehicle_version") ||
                    readOnlyOr ||
                    amountInsured === 0 ||
                    isRc
                  }
                  className={readOnlyOr ? "read-only" : undefined}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} xl={6}>
              <Form.Item
                className="accessories-item"
                label={ACCESSORIES_SPECIAL_RIM.label}
                name={ACCESSORIES_SPECIAL_RIM.name}
                rules={[
                  {
                    pattern: /^[\d]{0,10}$/,
                    message: "Máximo 10 dígitos",
                  },
                ]}
              >
                <InputNumber
                  aria-label="Accesorios del vehiculo"
                  placeholder={ACCESSORIES_SPECIAL_RIM.placeholder}
                  value={tires}
                  onChange={setTires}
                  disabled={
                    !form.getFieldValue("vehicle_version") ||
                    readOnlyOr ||
                    amountInsured === 0 ||
                    isRc
                  }
                  className={readOnlyOr ? "read-only" : undefined}
                />
              </Form.Item>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </>
  );
};

export default GeneralDataForm;
