export const formNames = {
  information: {
    title: "Solicitud PATENTE EXTRANJERA",
    REQUEST_ID: {
      name: "request_id",
      label: "Id solicitud",
      placeholder: "Se completa automáticamente",
    },
    ASSIGNED_POLICY_NUMBER: {
      name: "assigned_policy_number",
      label: "N° de póliza",
      placeholder: "Se completa automáticamente",
    },
    RENEW_POLICY_NUMBER: {
      name: "renew_policy_number",
      label: "Renueva póliza N°",
      placeholder: "Se completa automáticamente",
    },
    PRODUCER: {
      name: "producer",
      label: "Productor",
      placeholder: "Ingrese productor",
    },
    RISK_AREA: {
      name: "risk_area",
      label: "Zona riesgo",
      placeholder: "Se completa automáticamente",
    },
  },
  clientInformation: {
    title: "Datos cliente",
    COMPANY_NAME_OR_SURNAME: {
      name: "company_name_or_surname",
      label: "Razón social / apellido",
      placeholder: "Razón social / apellido",
    },
    FISCAL_CONDITION: {
      name: "fiscal_condition",
      label: "Condición fiscal",
      placeholder: "Seleccione condición fiscal",
    },
    CUIT_CUIL: {
      name: "cuit_cuil",
      label: "Pasaporte",
      placeholder: "Ingrese pasaporte",
    },
  },
  generalData: {
    title: "Datos generales",
    VEHICLE_BRAND: {
      name: "vehicle_brand",
      label: "Marca",
      placeholder: "Seleccione marca",
    },
    VEHICLE_MODEL: {
      name: "vehicle_model",
      label: "Modelo",
      placeholder: "Seleccione modelo",
    },
    VEHICLE_YEAR: {
      name: "vehicle_year",
      label: "Año",
      placeholder: "Seleccione año",
    },
    VEHICLE_VERSION: {
      name: "vehicle_version",
      label: "Versión",
      placeholder: "Seleccione versión",
    },
    VEHICLE_TYPE: {
      name: "vehicle_type",
      label: "Tipo de vehículo",
      placeholder: "Seleccione tipo de vehículo",
    },
    DESTINY: {
      name: "destiny",
      label: "Destino",
      placeholder: "Seleccione destino",
    },
    FUEL_TYPE: {
      name: "fuel_type",
      label: "Tipo de combustible",
      placeholder: "Seleccione tipo de combustible",
    },
    BODYWORK_TYPE: {
      name: "bodywork_type",
      label: "Tipo de carrocería",
      placeholder: "Seleccione tipo de carrocería",
    },
    ADDITIONAL_HAIL: {
      name: "additional_hail",
      label: "Adicional granizo",
      placeholder: "Seleccione adicional granizo",
    },
    COVERAGE: {
      name: "coverage",
      label: "Tipo de cobertura",
      placeholder: "Seleccione tipo de cobertura",
    },
    START_DATE: {
      name: "start_date",
      label: "Fecha de inicio",
      placeholder: "Seleccione fecha de inicio",
    },
    PRODUCT_CODE: {
      name: "product_code",
      label: "Código de producto",
      placeholder: "Se completa automáticamente",
    },
    INSURED_AMOUNT: {
      name: "insured_amount",
      label: "Suma asegurada",
      placeholder: "Ingrese suma asegurada",
    },
    TOTAL_ACCESSORIES: {
      name: "total_accessories",
      label: "Total accesorios",
      placeholder: "Ingrese total accesorios",
    },
    ADJUSTMENT_RATE: {
      name: "adjustment_rate",
      label: "Tasa de ajuste",
      placeholder: "Seleccione tasa de ajuste",
    },
    COVERAGE_TIME: {
      name: "coverage_time",
      label: "Período de cobertura",
      placeholder: "Seleccione período de cobertura",
    },
    TOWING_SERVICE: {
      name: "REMOLQUE_CC",
      label: "Servicio de remolque",
      placeholder: "Seleccione servicio de remolque",
    },
  },
  generalDataAccessories: {
    title: "Accesorios",
    ACCESSORIES_CUPULA: {
      name: "accessories_cupula",
      label: "Cúpula",
      placeholder: "$",
    },
    ACCESSORIES_FREEZER: {
      name: "accessories_freezer",
      label: "Equipo de frío",
      placeholder: "$",
    },
    ACCESSORIES_GNC: {
      name: "accessories_gnc",
      label: "GNC",
      placeholder: "$",
    },
    ACCESSORIES_SPECIAL_RIM: {
      name: "accessories_special_rim",
      label: "Llanta especial",
      placeholder: "$",
    },
  },
  userInformation: {
    title: "Datos asegurado",
    USER_HOME: {
      name: "user_home",
      label: "Domicilio",
      placeholder: "Ingrese domicilio",
    },
    USER_HOME_NUMBER: {
      name: "user_home_number",
      label: "Número",
      placeholder: "Ingrese número",
    },
    USER_HOME_FLOOR: {
      name: "user_home_floor",
      label: "Piso",
      placeholder: "Ingrese piso",
    },
    USER_HOME_DEPARTMENT: {
      name: "user_home_department",
      label: "Departamento",
      placeholder: "Ingrese departamento",
    },
    USER_PHONE: {
      name: "user_phone",
      label: "Teléfono",
      placeholder: "Ingrese teléfono",
    },
    USER_PROVINCE: {
      name: "user_province",
      label: "Provincia",
      placeholder: "Seleccione provincia",
    },
    USER_LOCATION: {
      name: "user_location",
      label: "Localidad",
      placeholder: "Seleccione localidad",
    },
    USER_POSTAL_CODE: {
      name: "user_postal_code",
      label: "Código postal",
      placeholder: "Ingrese código postal",
    },
    USER_EMAIL: {
      name: "user_email",
      label: "Email",
      placeholder: "Ingrese email",
    },
    USER_PAYMENT_METHOD: {
      name: "user_payment_method",
      label: "Tipo de Pago",
      placeholder: "Seleccione tipo de pago",
    },
    USER_PAYMENT_NUMBER: {
      name: "user_payment_number",
      label: "Número de Pago",
      placeholder: "Ingrese número de pago",
    },
    USER_PAYMENT_INSTALLMENT_TYPES: {
      name: "user_payment_installment_type",
      label: "Tipo de cuotas",
      placeholder: "Seleccione tipo de cuotas",
    },
  },
  naturalPerson: {
    title: "Datos adicionales (personas físicas)",
    NATURAL_PERSON_GENDER: {
      name: "natural_person_gender",
      label: "Género",
      placeholder: "Seleccione género",
    },
    NATURAL_PERSON_BIRTH_DATE: {
      name: "natural_person_birth_date",
      label: "Fecha de nacimiento",
      placeholder: "Seleccione fecha de nacimiento",
    },
    NATURAL_PERSON_BIRTH_PLACE: {
      name: "natural_person_birth_place",
      label: "Lugar de nacimiento",
      placeholder: "Ingrese lugar de nacimiento",
    },
    NATURAL_PERSON_NATIONALITY: {
      name: "natural_person_nationality",
      label: "Nacionalidad",
      placeholder: "Seleccione nacionalidad",
    },
  },
  legalPerson: {
    title: "Datos adicionales (personas jurídicas)",
    LEGAL_PERSON_REGISTRATION_DATE: {
      name: "legal_person_registration_date",
      label: "Fecha de registración",
      placeholder: "Ingrese fecha de registración",
    },
    LEGAL_PERSON_REGISTRATION_NUMBER: {
      name: "legal_person_registration_number",
      label: "Número de inscripción registral",
      placeholder: "Ingrese número de inscripción registral",
    },
    LEGAL_PERSON_CONTRACT_DATE: {
      name: "legal_person_contract_date",
      label: "Fecha de contrato",
      placeholder: "Ingrese fecha de contrato",
    },
    LEGAL_PERSON_MAIN_ACTIVITY: {
      name: "legal_person_main_activity",
      label: "Actividad principal",
      placeholder: "Ingrese actividad principal",
    },
  },
  vehicleData: {
    title: "Datos vehículo",
    VEHICLE_CHASIS_NUMBER: {
      name: "vehicle_chasis_number",
      label: "Número de chasis",
      placeholder: "Ingrese número de chasis",
    },
    VEHICLE_ENGINE_NUMBER: {
      name: "vehicle_engine_number",
      label: "Número de motor",
      placeholder: "Ingrese número de motor",
    },
    VEHICLE_PATENT: {
      name: "vehicle_patent",
      label: "Patente",
      placeholder: "Ingrese patente",
    },
  },
  pledgeCreditorData: {
    title: "Datos acreedor prendario",
    PLEDGE_CREDITOR_YES_OR_NO: {
      name: "pledge_creditor_yes_or_no",
      label: "Acreedor prendario",
      placeholder: "Ingrese acreedor prendario",
    },
    PLEDGE_CREDITOR_COMPANY_NAME_OR_SURNAME: {
      name: "pledge_creditor_company_name_or_surname",
      label: "Apellido",
      placeholder: "Ingrese apellido",
    },
    PLEDGE_CREDITOR_NAME: {
      name: "pledge_creditor_name",
      label: "Nombre",
      placeholder: "Ingrese nombre",
    },
    PLEDGE_CREDITOR_HOME: {
      name: "pledge_creditor_home",
      label: "Domicilio",
      placeholder: "Ingrese domicilio",
    },
    PLEDGE_CREDITOR_HOME_NUMBER: {
      name: "pledge_creditor_home_number",
      label: "Número",
      placeholder: "Ingrese número",
    },
    PLEDGE_CREDITOR_HOME_FLOOR: {
      name: "pledge_creditor_home_floor",
      label: "Piso",
      placeholder: "Ingrese piso",
    },
    PLEDGE_CREDITOR_HOME_DEPARTMENT: {
      name: "pledge_creditor_home_department",
      label: "Departamento",
      placeholder: "Ingrese departamento",
    },
    PLEDGE_CREDITOR_PROVINCE: {
      name: "pledge_creditor_province",
      label: "Provincia",
      placeholder: "Seleccione provincia",
    },
    PLEDGE_CREDITOR_LOCATION: {
      name: "pledge_creditor_location",
      label: "Localidad",
      placeholder: "Seleccione localidad",
    },
    PLEDGE_CREDITOR_POSTAL_CODE: {
      name: "pledge_creditor_postal_code",
      label: "Código postal",
      placeholder: "Seleccione código postal",
    },
  },
  legalRepresentative: {
    title: "Datos representante legal",
    LEGAL_REPRESENTATIVE_YES_OR_NO: {
      name: "legal_representative_yes_or_no",
      label: "Representante legal",
    },
    LEGAL_REPRESENTATIVE_CUIT: {
      name: "legal_representative_cuit",
      label: "CUIT / CUIL",
      placeholder: "Ingrese CUIT / CUIL",
    },
    LEGAL_REPRESENTATIVE_NAME_SURNAME: {
      name: "legal_representative_name_surname",
      label: "Nombre y apellido",
      placeholder: "Ingrese nombre y apellido",
    },
    LEGAL_REPRESENTATIVE_HOME: {
      name: "legal_representative_home",
      label: "Domicilio",
      placeholder: "Ingrese domicilio",
    },
    LEGAL_REPRESENTATIVE_NUMBER: {
      name: "legal_representative_number",
      label: "Número",
      placeholder: "Ingrese número de domicilio",
    },
    LEGAL_REPRESENTATIVE_FLOOR: {
      name: "legal_representative_floor",
      label: "Piso",
      placeholder: "Ingrese piso",
    },
    LEGAL_REPRESENTATIVE_DEPARTMENT: {
      name: "legal_representative_department",
      label: "Departamento",
      placeholder: "Ingrese departamento",
    },
    LEGAL_REPRESENTATIVE_PHONE: {
      name: "legal_representative_phone",
      label: "Teléfono",
      placeholder: "Ingrese teléfono",
    },
    LEGAL_REPRESENTATIVE_EMAIL: {
      name: "legal_representative_email",
      label: "Email",
      placeholder: "Ingrese email",
    },
    LEGAL_REPRESENTATIVE_GENDER: {
      name: "legal_representative_gender",
      label: "Género",
      placeholder: "Selecione género",
    },
    LEGAL_REPRESENTATIVE_PROVINCE: {
      name: "legal_representative_province",
      label: "Provincia",
      placeholder: "Selecione provincia",
    },
    LEGAL_REPRESENTATIVE_LOCATION: {
      name: "legal_representative_location",
      label: "Localidad",
      placeholder: "Selecione localidad",
    },
    LEGAL_REPRESENTATIVE_POSTAL_CODE: {
      name: "legal_representative_postal_code",
      label: "Código postal",
      placeholder: "Selecione código postal",
    },
    LEGAL_REPRESENTATIVE_BIRTH_DATE: {
      name: "legal_representative_birth_date",
      label: "Fecha de nacimiento",
      placeholder: "Selecione fecha de nacimiento",
    },
    LEGAL_REPRESENTATIVE_BIRTH_PLACE: {
      name: "legal_representative_birth_place",
      label: "Lugar de nacimiento",
      placeholder: "Ingrese lugar de nacimiento",
    },
    LEGAL_REPRESENTATIVE_NATIONALITY: {
      name: "legal_representative_nationality",
      label: "Nacionalidad",
      placeholder: "Selecione nacionalidad",
    },
  },
  quote: {
    title: "Cotización",
    QUOTATION_PRIMA: {
      name: "quotation_prima",
      label: "Prima",
      placeholder: "Se completa automáticamente",
    },
    QUOTATION_PREMIUM: {
      name: "quotation_premium",
      label: "Premio",
      placeholder: "Se completa automáticamente",
    },
    QUOTATION_RESULT_CODE: {
      name: "quotation_result_code",
      label: "Código de resultado",
      placeholder: "Se completa automáticamente",
    },
    QUOTATION_RESULT_MESSAGE: {
      name: "quotation_result_message",
      label: "Mensaje resultado",
      placeholder: "Se completa automáticamente",
    },
  },
};
