import { Col, Collapse, Form, FormInstance, Input, Row, Select } from "antd";
import { formNames } from "../utils/formNames";
import { useEffect } from "react";

const { Panel } = Collapse;
interface IClientInformationProps {
  fiscalConditionOptions: () => false | JSX.Element[];
  form: FormInstance;
  cuit?: string;
  setPersonType: React.Dispatch<React.SetStateAction<string>>;
  isRenovation: boolean;
  setFiscalCondition: React.Dispatch<React.SetStateAction<string | undefined>>;
  readOnlyOr: boolean;
}

export const ClientInformationForm = (
  props: IClientInformationProps
): JSX.Element => {
  const {
    fiscalConditionOptions,
    cuit,
    setPersonType,
    isRenovation,
    setFiscalCondition,
    readOnlyOr,
  } = props;

  const { COMPANY_NAME_OR_SURNAME, CUIT_CUIL, FISCAL_CONDITION, title } =
    formNames.clientInformation;

  const updatePersonType = async (cuitValue: string): Promise<void> => {
    setPersonType('personType');
    //CAK AJUSTAR EL VALOR DE ARRIBA
    
  };

  useEffect(() => {
    if (cuit) {
      updatePersonType(cuit);
    }
  }, [cuit]);
  
  return (
    <Collapse defaultActiveKey={["1"]} className="collapse">
      <Panel header={title} key="1">
        <Row justify="start" gutter={40}>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Ingrese Pasaporte",
                },
              ]}
              label={CUIT_CUIL.label}
              name={CUIT_CUIL.name}
            >
                <Input
                  value={cuit}
                  placeholder={CUIT_CUIL.placeholder}
                />
              
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              label={COMPANY_NAME_OR_SURNAME.label}
              rules={[{ required: true, message: "Ingrese Razón social / apellido" }]}
              name={COMPANY_NAME_OR_SURNAME.name}
            >
              <Input
                placeholder={COMPANY_NAME_OR_SURNAME.placeholder}
                value={cuit}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                { required: true, message: "Seleccione condición fiscal" },
              ]}
              label={FISCAL_CONDITION.label}
              name={FISCAL_CONDITION.name}
            >
              <Select
                placeholder={FISCAL_CONDITION.placeholder}
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                dropdownMatchSelectWidth={false}
                onSelect={(value: string) => setFiscalCondition(value)}
                aria-placeholder="Seleccione"
                disabled={isRenovation || readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              >
                {fiscalConditionOptions()}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
};

export default ClientInformationForm;
