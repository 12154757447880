import { Col, Collapse, Form, Input, Row, Select, FormInstance } from "antd";
import { useCallback } from "react";

import GraphqlService from "../../../services/graphql/GraphqlService";
import { Query } from "../../../services/graphql/query";
import { IProducer } from "../../../services/Producer";
import { IUser } from "../../../services/user";
import { CustomMessage } from "../../../shared";
import { camelCase } from "../../../shared/utils";
import SelectWithFetch from "../../utils/SelectWithFetch";
import { formNames } from "../utils/formNames";
import { IVehicleType } from "../../../services/IVehicleType";

const { Panel } = Collapse;

interface IInsuredDataProps {
  user: IUser;
  initialProducer?: string;
  isRenovation: boolean;
  form: FormInstance;
  setSelectedProducer: React.Dispatch<
    React.SetStateAction<IProducer | undefined>
  >;
  selectedProducer: IProducer | undefined;
  idsLiderarForMotorcycles: string[];
  selectedVehicleType: IVehicleType | undefined;
  readOnlyOr: boolean;
  className?: string;
}

export const InformationForm = (props: IInsuredDataProps): JSX.Element => {
  const {
    user,
    initialProducer,
    isRenovation,
    form,
    setSelectedProducer,
    selectedProducer,
    idsLiderarForMotorcycles,
    selectedVehicleType,
    readOnlyOr,
    className
  } = props;

  const {
    ASSIGNED_POLICY_NUMBER,
    PRODUCER,
    RENEW_POLICY_NUMBER,
    REQUEST_ID,
    RISK_AREA,
    title,
  } = formNames.information;

  const { customRequest } = GraphqlService();
  const { messageError } = CustomMessage();

  const handleProducerSearch = async (value: string) => {
    try {
      const data: IProducer[] = await customRequest({
        query: Query.producers,
        variables: {
          searchText: value,
          filter: {
            user_id: user?.id,
            activo_web: true,
          },
        },
      });
      return data;
    } catch (error) {
      messageError({
        context: "handleProducerSearch",
        message: "Error al obtener los productores",
      });
    }
  };

  const getProductCode = useCallback(
    (value: IProducer): void => {
      setSelectedProducer(value);
      form.setFieldsValue({
        [RISK_AREA.name]: value.zona_riesgo,
      });
      if (selectedProducer && idsLiderarForMotorcycles && selectedVehicleType) {
        if (idsLiderarForMotorcycles.includes(selectedVehicleType.id_liderar)) {
          form.setFieldsValue({
            [formNames.generalData.PRODUCT_CODE.name]:
              selectedProducer.cod_prod_moto,
          });
        } else {
          form.setFieldsValue({
            [formNames.generalData.PRODUCT_CODE.name]:
              selectedProducer.cod_prod_auto,
          });
        }
      }
    },
    [selectedProducer, idsLiderarForMotorcycles, form, selectedVehicleType]
  );

  const handleClearProducer = () => {
    setSelectedProducer(undefined);
    form.setFieldsValue({
      [RISK_AREA.name]: undefined,
    });
  };

  return (
    <Collapse defaultActiveKey={["1"]} className={className ? className + " collapse" : " collapse"} >
      <Panel header={title} key="1">
        <Row justify="space-between" gutter={40}>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[{ required: false, message: "Ingrese Id de solicitud" }]}
              label={REQUEST_ID.label}
              name={REQUEST_ID.name}
            >
              <Input
                placeholder={REQUEST_ID.placeholder}
                disabled
                className={readOnlyOr ? "read-only" : undefined}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                {
                  required: false,
                  message: "Ingrese número de póliza asignada",
                },
              ]}
              label={ASSIGNED_POLICY_NUMBER.label}
              name={ASSIGNED_POLICY_NUMBER.name}
            >
              <Input
                type="text"
                placeholder={ASSIGNED_POLICY_NUMBER.placeholder}
                disabled
                className={readOnlyOr ? "read-only" : undefined}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                {
                  required: isRenovation,
                  message: "Ingrese número de poliza a renovar",
                },
              ]}
              label={RENEW_POLICY_NUMBER.label}
              name={RENEW_POLICY_NUMBER.name}
            >
              <Input
                type="text"
                placeholder={RENEW_POLICY_NUMBER.placeholder}
                disabled
                className={readOnlyOr ? "read-only" : undefined}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Ingrese productor",
                },
              ]}
              label={PRODUCER.label}
              name={PRODUCER.name}
            >
              <SelectWithFetch
                lengthValueToFetch={3}
                disabled={isRenovation || readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
                onSelect={getProductCode}
                query={handleProducerSearch}
                onClear={handleClearProducer}
                placeholder={PRODUCER.placeholder}
                optionRender={(item: IProducer) => (
                  <Select.Option value={item.id_liderar} key={item.id_liderar}>
                    {camelCase(item.nombre)} - {item.id_liderar}
                  </Select.Option>
                )}
                {...(initialProducer ? { initialValue: initialProducer } : {})}
                {...(initialProducer ? { initialData: [initialProducer] } : {})}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="start" gutter={40}>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              label={RISK_AREA.label}
              rules={[{ required: true, message: "Ingrese zona riesgo" }]}
              name={RISK_AREA.name}
            >
              <Input
                placeholder={RISK_AREA.placeholder}
                disabled
                className={readOnlyOr ? "read-only" : undefined}
              />
            </Form.Item>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
};

export default InformationForm;
