export const BRANDS = `
query getAllBrands($filter: FilterVehicleTypeForeignInput){
  getAllBrands (filter: $filter){
    __typename
    ... on Brand {
      id
      brand
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
