import { useCallback, useState } from "react";
import { convertSpecialStringToNormalStringObject } from "../../utils/convertSpecialStringToNormalStringObject";
import { ICuitOnline } from "../../services/CuitOnline";
import GraphqlService from "../../services/graphql/GraphqlService";
import { CustomMessage } from "../CustomMessage";

const useCuitVerification = () => {
  const { customRequest, Query } = GraphqlService();
  const { messageError } = CustomMessage();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const verifyCuit = useCallback((value: string): Promise<ICuitOnline[]> => {
    return new Promise((resolve) => {
      if (Number.isNaN(value)) {
        return resolve([]);
      }
      if (!value) {
        return resolve([]);
      }
      setIsLoading(() => true);
      customRequest({
        query: Query.cuitOnline,
        variables: {
          input: value,
        },
      })
        .then((data: ICuitOnline[]) =>
          resolve(data.map((item)=>convertSpecialStringToNormalStringObject(item)))
        )
        .catch(() => {
          messageError({
            context: "verifyCuit",
            message: "CUIT no encontrado - por favor intente nuevamente",
          });
        })
        .finally(() => {
          setIsLoading(() => false);
        });
    });
  }, []);

  return { verifyCuit, isLoading };
};

export default useCuitVerification;
