export const PAYMENTINSTALLMENTTYPES = `
query payment_installment_types(
  $filter: FilterPaymentInstallmentTypeInput
  $orderBy: PaymentInstallmentTypeOrderInput
  $skip: Int
  $take: Int
  $searchText:String
) {
  payment_installment_types(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take,searchText:$searchText){
    __typename
    ...on PaymentInstallmentType {
      id
      name
      code
    }
    ...on ResultError{
      status_code
      message
    }
  }

}`;
