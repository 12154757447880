import { useState, useCallback, useEffect, useContext } from "react";
import { Select, FormInstance } from "antd";
import moment from "moment";

import {
  IbrandModelByBrandList,
  IbrandModelByBrandModelYear,
  IyearsByBrandAndModel,
} from "../../../services/BrandModel";
import GraphqlService from "../../../services/graphql/GraphqlService";
import { IBrand } from "../../../services/IBrand";
import { CustomMessage } from "../../../shared";
import { IRenovation } from "../../../services/Renovation";
import { IVehicleType } from "../../../services/IVehicleType";
import { ContextApp } from "../../../shared/ContextApp";
import { IDestino } from "../../../services/IDestino";
import { formNames } from "../utils/formNames";
import { EnumsValues } from "../../../shared/EnumsValues";

interface IuseVehicleProps {
  form: FormInstance;
  renovationData?: IRenovation;
  formType: string;
}

const useVehicleForm = (props: IuseVehicleProps) => {
  const { form, renovationData, formType } = props;

  const { user } = useContext(ContextApp);
  const { customRequest, Query } = GraphqlService();
  const { messageError } = CustomMessage();

  const [vehicleBrand, setVehicleBrand] = useState<IBrand[]>([]);
  const [vehicleModel, setVehicleModel] = useState<string[]>([]);
  const [yearList, setYearList] = useState<string[]>([]);
  const [vehicleVersionList, setVehicleVersionList] = useState<
    IbrandModelByBrandModelYear[]
  >([]);
  const [vehicleTypes, setVehicleTypes] = useState<IVehicleType[]>([]);
  const [destinos, setDestinos] = useState<IDestino[]>([]);

  //Preload Vehicle Form
  useEffect(() => {
    if (renovationData) {
      form.setFieldsValue({
        [formNames.generalData.ADDITIONAL_HAIL.name]:
          renovationData?.policy?.vehiculo[0]?.adicional_granizo === "S",
        [formNames.generalData.START_DATE.name]: moment(
          renovationData.due_date
        ).utc(false),
      });
    }
  }, [form, renovationData]);

  const getVehicleBrands = useCallback(async () => {
    try {
      const response: IBrand[] = await customRequest({
        query: Query.brands,
        variables: {
          filter: {
            type : ['080','081', '082'],
          }
        }
      });
      if (response.length) {
        setVehicleBrand(response);
      }
    } catch (error) {
      messageError({
        context: "getVehicleBrands",
        message: "Error al obtener las marcas de vehículos",
      });
    }
  }, []);

  useEffect(() => {
    getVehicleBrands();
  }, []);

  const brandOptions = () =>
    vehicleBrand.length !== 0 &&
    vehicleBrand.map((item: IBrand, index: number) => (
      <Select.Option value={item.brand} key={index}>
        {item.brand}
      </Select.Option>
    ));

  const getBrandModel = useCallback(async (brandSelected: string) => {
    try {
      const response: IbrandModelByBrandList = await customRequest({
        query: Query.brandModelByBrandList,
        variables: {
          brand: brandSelected,
        },
      });

      if (response && response.array_model.length) {
        //filtramos por vehiculos habilitados: 080, 081 y 082
        response.array_model.map((item) => console.log(item));
        setVehicleModel(response.array_model);
      }
    } catch (error) {
      messageError({
        context: "brandModelByBrandList",
        message: "Error al obtener los modelos de vehículos",
      });
    }
  }, []);

  const vehicleModelOptions = () =>
    vehicleModel.length !== 0 &&
    vehicleModel.map((item: string, index: number) => (
      <Select.Option value={item} key={index}>
        {item}
      </Select.Option>
    ));

  const getYearsByBrandAndModel = useCallback(
    async (brandSelected: string, modelSelected: string) => {
      try {
        const response: IyearsByBrandAndModel = await customRequest({
          query: Query.yearsByBrandAndModel,
          variables: {
            brand: brandSelected,
            model: modelSelected,
          },
        });
        setYearList(response.array_year);
      } catch (error) {
        messageError({
          context: "yearsByBrandAndModel",
          message:
            "Error al obtener el listado de años disponibles para los vehículos seleccionados",
        });
      }
    },
    []
  );

  const yearOptions = () =>
    yearList.length !== 0 &&
    yearList.map((item: string, index: number) => {
      return (
        <Select.Option value={item} key={index}>
          {item}
        </Select.Option>
      );
    });

  const getBrandModelByBrandModelYear = useCallback(
    async (
      brandSelected: string,
      modelSelected: string,
      yearSelected: string
    ) => {
      try {
        const response: IbrandModelByBrandModelYear[] = await customRequest({
          query: Query.brandModelByBrandModelYear,
          variables: {
            brand: brandSelected,
            model: modelSelected,
            year: yearSelected,
          },
        });
        setVehicleVersionList(response);
        return response;
      } catch (error) {
        messageError({
          context: "brandModelByBrandModelYear",
          message: "Error al obtener version de vehículos",
        });
      }
    },
    [renovationData]
  );

  const vehicleVersionOptions = () =>
    vehicleVersionList &&
    vehicleVersionList.map(
      (item: IbrandModelByBrandModelYear, index: number) => {
        return (
          <Select.Option value={item.model} key={index}>
            {item.model}
          </Select.Option>
        );
      }
    );

  const clearOnChangeBrand = () => {
    setVehicleModel([]);
    setYearList([]);
    setVehicleVersionList([]);
    setVehicleTypes([]);
    setDestinos([]);
  };
  const clearOnChangeModel = () => {
    setYearList([]);
    setVehicleVersionList([]);
    setVehicleTypes([]);
    setDestinos([]);
  };

  const clearOnChangeYear = () => {
    setVehicleVersionList([]);
    setVehicleTypes([]);
    setDestinos([]);
  };
  const clearOnChangeVersion = () => {
    setVehicleTypes([]);
    setDestinos([]);
  };

  const clearOnChangeVehicleType = () => {
    setDestinos([]);
  };

  const getVehicleType = useCallback(
    async (id_liderar: string[]) => {
      try {
        const idLiderarFilter =
          formType === EnumsValues.formType.directEmissionForeign ||
          formType === EnumsValues.formType.renovation;
          console.log("id_liderar sin filtrado")
          console.log(id_liderar)
          
          id_liderar = id_liderar.filter((item) => {
            console.log(item)
            if(item == '080' || item == '081' || item == '082'){
              return true;
            }else{
              return false;
            }
          })
          console.log("id_liderar filtrado")
          console.log(id_liderar)
        const response: IVehicleType[] = await customRequest({
          query: Query.vehicle_types,
          variables: {
            filter: {
              activo_web: true,
              id_liderar: idLiderarFilter ? id_liderar : undefined,
              user_id: user?.id,
            },
            orderBy: { field: "description", direction: "asc" },
          },
        });

        if (response && response.length > 0) {
          setVehicleTypes(response);
        }
      } catch (error) {
        messageError({
          context: "getVehicleType",
          message: "Error al obtener los tipos de vehículos",
        });
      }
    },
    [user, formType]
  );

  const vehicleTypeOptions = () =>
    vehicleTypes &&
    vehicleTypes.map((item: IVehicleType) => (
      <Select.Option value={item.id_liderar} key={item.id_liderar}>
        {item.description}
      </Select.Option>
    ));

  const getDestinos = async (vehicleTypeIDLiderar: string[] | undefined) => {
    try {
      const idLiderarFilter =
        formType === EnumsValues.formType.directEmissionForeign ||
        formType === EnumsValues.formType.renovation;

      const response: IDestino[] = await customRequest({
        query: Query.destinys,
        variables: {
          filter: {
            activo_web: true,
            id_liderar: idLiderarFilter ? vehicleTypeIDLiderar : undefined,
          },
        },
      });
      setDestinos(response);
    } catch (error) {
      messageError({
        context: "getDestinos",
        message: "Error al obtener los destinos",
      });
    }
  };

  const destinyOptions = useCallback(
    (vehicleDestinyFiltered: string[] | undefined) =>
      vehicleDestinyFiltered &&
      destinos
        .filter((destiny) =>
          vehicleDestinyFiltered.includes(destiny.id_liderar)
        )
        .map((item: IDestino) => (
          <Select.Option value={item.id_liderar} key={item.id}>
            {item.description}
          </Select.Option>
        )),
    [destinos]
  );

  return {
    vehicleBrand,
    brandOptions,
    getBrandModel,
    vehicleModelOptions,
    vehicleModel,
    getYearsByBrandAndModel,
    yearOptions,
    yearList,
    getBrandModelByBrandModelYear,
    vehicleVersionOptions,
    vehicleVersionList,
    clearOnChangeBrand,
    clearOnChangeModel,
    clearOnChangeYear,
    clearOnChangeVersion,
    getVehicleType,
    vehicleTypeOptions,
    vehicleTypes,
    destinyOptions,
    destinos,
    clearOnChangeVehicleType,
    getDestinos,
  };
};

export default useVehicleForm;
