import { RouteConfigComponentProps } from "react-router-config";
import { Form, Modal } from "antd";
import { useState, useContext, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router";
import moment from "moment";

import useRenovationPreloadForm from "../hooks/useRenovationPreloadForm";
import useSelectInput from "../hooks/useSelectInput";
import { ContextApp, CustomMessage, EnumsValues } from "../../../shared";
import { FORMAT_DATE_TIME_4 } from "../../../shared/MomentJS";
import {
  ClientInformationForm,
  GeneralDataForm,
  InformationForm,
  LegalPersonForm,
  NaturalPersonForm,
  UserInformationForm,
  VehicleDataForm,
  PledgeCreditorDataForm,
  QuoteForm,
  FormButtons,
  LegalRepresentativeForm,
} from "../components";
import {
  IEmissionForeignParams,
  ILegalPersonParams,
  ILegalRepresentativeParams,
  INaturalPersonParams,
  IPledgedCreditorParams,
} from "../../../services/EmissionParamsForm";
import useVehicleForm from "../hooks/useVehicleForm";
import {
  IEmissionErrorResponse,
  IUploadedFiles,
  IValuesEmisionRenovationForm,
} from "../interfaces/IValuesEmisionRenovationForm";
import useAgeVerification from "../../../shared/hooks/useAgeVerification";
import GraphqlService, {
  IFileData,
} from "../../../services/graphql/GraphqlService";
import { formNames } from "../utils/formNames";
import { zeroPad } from "../../../shared/utils";
import { IProducer } from "../../../services/Producer";
import useEmissionPreloadForm from "../hooks/useEmissionPreloadForm";
import VehiclePicture from "../components/VehiclePicture";
import { IVehicleType } from "../../../services/IVehicleType";
import { ModalConfirm } from "../../utils/ModalConfirm";
import { useCheckAuthority } from "../../../shared/CustomHooks";

import "../styles/style.less";

const RenovationForeignFormPage = (
  routeProps: RouteConfigComponentProps
): JSX.Element => {
   const {  isAdult } = useAgeVerification(); //ageVerification,
  const { customRequest, Mutation, customFileRequest } = GraphqlService();
  const {  messageModalError } = CustomMessage(); //messageError,
  const [quotationId, setQuotationId] = useState<number>();
  const [form] = Form.useForm();
  const { user } = useContext(ContextApp);
  const params: { id?: string } = useParams();
  const location = useLocation();
  const [formType, setFormType] = useState<string>("");
  const [renovationBlocked, setRenovationBlocked] = useState<boolean>(false);

  const type = () => {
    if (params.id && location.pathname.includes("/policies-to-renewed-list")) {
      setFormType("policies-to-renewed-list");
    } else if (params.id && location.pathname.includes("/direct-emission-foreign")) {
      setFormType("direct-emission-foreign");
      if (location.search.includes("?historical")) {
        //CAK VER
        setFormType("direct-emission-historical-read-only");
      }
    } else if (
      params?.id &&
      location.pathname.includes("/renewed-quotes-list")
    ) {
      setFormType("renewed-quotes-list");
      if (location.search.includes("?quotationId=")) {
        setQuotationId(Number(location.search.split("=")[1]));
      }
    } else if (!params?.id && location.pathname.includes("/direct-emission-foreign")) {
      setFormType("direct-emission-foreign");
    }
  };

  useEffect(() => {
    if (location && params) {
      type();
    }
  }, [params, location]);

  const { renovationData, quotation } = useRenovationPreloadForm({
    form,
    renovationId:
      params.id && location.pathname.includes("/renovation")
        ? Number(params?.id)
        : undefined,
    quotationId,
  });

  const { emissionReadOnly, emission } = useEmissionPreloadForm({
    form,
    emissionId:
      params.id && location.pathname.includes("/direct-emission-foreign")
        ? Number(params?.id)
        : undefined,
    formType,
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [personType, setPersonType] = useState<string>(EnumsValues.PersonType.FISICA);
  const [amountOutOfRange, setAmountOutOfRange] = useState<boolean>(false);
  const [accesoriesError, setAccesoriesError] = useState<boolean>(false);
  const [additionalHail, setAdditionalHail] = useState<boolean>(false);
  const [billingCode, setBillingCode] = useState<string>("");
  const [selectedProducer, setSelectedProducer] = useState<IProducer>();
  const [selectedVehicleType, setSelectedVehicleType] =
    useState<IVehicleType>();
  const [uploadedFiles, setUploadedFiles] = useState<IUploadedFiles>();
  const [errorResponse, setErrorResponse] = useState<IEmissionErrorResponse>({
    message: "",
    status_code: 0,
  });
  const [fiscalCondition, setFiscalCondition] = useState<string>();

  const {
    vehicleBrand,
    brandOptions,
    getBrandModel,
    vehicleModelOptions,
    vehicleModel,
    getYearsByBrandAndModel,
    yearOptions,
    yearList,
    getBrandModelByBrandModelYear,
    vehicleVersionOptions,
    vehicleVersionList,
    clearOnChangeBrand,
    clearOnChangeModel,
    clearOnChangeYear,
    getVehicleType,
    vehicleTypeOptions,
    vehicleTypes,
    destinyOptions,
    destinos,
    clearOnChangeVersion,
    clearOnChangeVehicleType,
    getDestinos,
  } = useVehicleForm({ form, renovationData, formType });

  const {
    fuelTypes,
    fuelOptions,
    bodyworkTypes,
    gendersTypes,
    genderOptions,
    paymentOptions,
    nationalityOptions,
    minBornTime,
    idsLiderarForMotorcycles,
    fiscalConditionOptions,
    provinces,
    provinceOptions,
    bodyWorkOptions,
    coveragePeriedOptions,
    coverageTypeOptions,
    provinceOptionsId,
    adjustmentRateOptions,
    adjustmentRates,
    getFuelTypes,
    getBodyworkTypes,
    getCoverageTypes,
    paymentInstallmentTypeOptions,
  } = useSelectInput({ renovationData, formType });

  const contextName = renovationData ? "Renovation" : "Emission";

  const saveImages = () => {
    if (uploadedFiles && Object.keys(uploadedFiles).length) {
      Object.keys(uploadedFiles).forEach((fileKey) => {
        const file = uploadedFiles[fileKey];
        const variables: any = {
          input: {
            file_type_id: EnumsValues.FileType.IMAGEN_PATENTE_POLIZA,
          },
          licensePlate: form.getFieldValue([
            formNames.vehicleData.VEHICLE_PATENT.name,
          ]),
        };
        if (fileKey && file) {
          variables.file = null;
          let fileArray: IFileData[] = [];
          fileArray = [
            {
              file,
              path: "variables.file",
            },
          ];

          try {
            const response = customFileRequest(
              {
                mutation: Mutation.saveFile,
                variables,
              },
              fileArray
            );
            if (!response) {
              throw new Error();
            }
          } catch (error: any) {
            messageModalError({
              context: `${contextName}.onFinish.2`,
              message:
                error?.message || `Error al cargar la imagen ${file.name}`,
            });
          }
        }
      });
    }
  };

  const autoClosableModal = (title: string, id: string) => {
    const secondsToGo = 5;
    const modal = Modal.info({
      title,
      content: `Número de póliza: ${id}`,
      className: "AutoClosableModal",
      centered: true,
    });
    setTimeout(() => {
      modal.destroy();
    }, secondsToGo * 1000);
  };

  const getAccesories = () => {
    const code_acc: string[] = [];
    const value_acc: string[] = [];

    const gnc: number = form.getFieldValue(
      formNames.generalDataAccessories.ACCESSORIES_GNC.name
    );

    const tires: number = form.getFieldValue(
      formNames.generalDataAccessories.ACCESSORIES_SPECIAL_RIM.name
    );

    const freezer: number = form.getFieldValue(
      formNames.generalDataAccessories.ACCESSORIES_FREEZER.name
    );

    const cupula: number = form.getFieldValue(
      formNames.generalDataAccessories.ACCESSORIES_CUPULA.name
    );

    if (gnc) {
      code_acc.push(EnumsValues.accessoryIdLiderar.gnc);
      value_acc.push(String(gnc));
    }

    if (tires) {
      code_acc.push(EnumsValues.accessoryIdLiderar.tires);
      value_acc.push(String(tires));
    }

    if (freezer) {
      code_acc.push(EnumsValues.accessoryIdLiderar.freezer);
      value_acc.push(String(freezer));
    }

    if (cupula) {
      code_acc.push(EnumsValues.accessoryIdLiderar.cupula);
      value_acc.push(String(cupula));
    }

    return { code_acc, value_acc };
  };

  
  const onFinish = async (values: IValuesEmisionRenovationForm) => {
    setIsLoading(true);
    console.log("es adulta?", isAdult)
    
    let codigo_de_productor = "";
    if (typeof values.producer === "string") {
      codigo_de_productor = values.producer;
    } else {
      codigo_de_productor = values.producer.value;
    }

    let cuit_number = "";
    if (typeof values.cuit_cuil === "string") {
      cuit_number = values.cuit_cuil;
    } else {
      cuit_number = values.cuit_cuil.value;
    }

    let birth_date = "";
    if (personType === EnumsValues.PersonType.FISICA) {
      birth_date = moment(values.natural_person_birth_date).format(
        FORMAT_DATE_TIME_4
      );
    } else {
      birth_date = moment(values.legal_person_registration_date).format(
        FORMAT_DATE_TIME_4
      );
    }

    const { code_acc } = getAccesories(); //comento por pedido de remover campo suma asegurada , value_acc

    const lifePolicyAccepted = () => {
      const showModal =
        formType === EnumsValues.formType.directEmissionForeign &&
        (values.fiscal_condition === EnumsValues.FiscalCondition.ConsFinal);
      return showModal ? true : undefined;
    };
    const remolque_cc_value = "N"; //Por pedido, se ocultan varios campos, incluido remolque
    const emissionParams: IEmissionForeignParams = {
      RENUEVA_POL: renovationData ? values.renew_policy_number : undefined,
      ADIC_GRANIZO: values.additional_hail ? "S" : "N",
      AJU_AUT: zeroPad(Number(values.adjustment_rate), 3), //"0", //Por pedido, se ocultan varios campos, incluido Ajuste
      ANIO: values.vehicle_year,
      CARROCERIA: values.bodywork_type,
      CHASIS: values.vehicle_chasis_number,
      COBERTURA: billingCode + values.coverage,
      COD_ACC: code_acc,
      COD_PROD: codigo_de_productor,
      COD_PROV: values.user_province,
      COD_TARJETA: values.user_payment_method,
      COND_FISCAL: values.fiscal_condition,
      COS_POS: values.user_postal_code,
      CUIT: cuit_number,
      DEPARTAMENTO: values.user_home_department,
      DOMIC_CALLE: values.user_home,
      DOMIC_NRO: String(values.user_home_number),
      FECHA_NAC: birth_date,
      LIFE_POLICY_ACCEPTED: lifePolicyAccepted(),
      LOCALIDAD: values.user_location,
      MARCA: values.vehicle_brand,
      MODELO: values.vehicle_version,
      MOTOR: values.vehicle_engine_number,
      NRO_DOC: cuit_number, // No se pide DNI cuit_number.slice(2, -1),
      NRO_PAGO: values.user_payment_number,
      PATENTE: "PE:" + values.vehicle_patent, //values.vehicle_patent, // Si mando PE da error de patente "PE", // Por pedido va PE en lugar de values.vehicle_patent,
      PERIODO_COB: values.coverage_time,
      PISO: values.user_home_floor,
      PRODUCTO: values.product_code,
      RAZON_SOCIAL: values.company_name_or_surname,
      SEXO:
        personType === EnumsValues.PersonType.FISICA
          ? values.natural_person_gender!
          : EnumsValues.Gender.OTROS,
      SUMA_ACC: [""], //Por pedido, se ocultan varios campos, incluido  suma asegurada  - value_acc,
      SUMA_ASEG: String(values.insured_amount),
      TEL: String(values.user_phone),
      TIPO_COMB: values.fuel_type,
      TIPO_DOC: "PA",
      TIPO_VEH: values.vehicle_type,
      USO: values.destiny,
      MAIL_ASEG: values.user_email,
      VIG_DESDE: moment(values.start_date).format(FORMAT_DATE_TIME_4),
      ZONA: values.risk_area,
      REMOLQUE_CC: remolque_cc_value
    };

    let pledgedCreditorParams: IPledgedCreditorParams;
    if (values.pledge_creditor_yes_or_no) {
      pledgedCreditorParams = {
        business_name_surname: values.pledge_creditor_company_name_or_surname!,
        name: values.pledge_creditor_name!,
        home_street: values.pledge_creditor_home!,
        home_number: String(values.pledge_creditor_home_number!),
        floor: values.pledge_creditor_home_floor!,
        department: values.pledge_creditor_home_department!,
        province_id: values.pledge_creditor_province!,
        postal_code_id: Number(values.pledge_creditor_postal_code!),
      };
    }
    const genderId = (gender_id_liderar: string) => {
      const selectedGender = gendersTypes.find(
        (gender) => gender.id_liderar === gender_id_liderar
      );
      return selectedGender ? selectedGender.id : EnumsValues.GenderId.OTROS;
    };

    let naturalPersonParams: INaturalPersonParams;
    let legalPersonParams: ILegalPersonParams;
    if (personType === EnumsValues.PersonType.FISICA) {
      naturalPersonParams = {
        birth_date: moment(values.natural_person_birth_date).format(
          FORMAT_DATE_TIME_4
        ),
        birth_place: values.natural_person_birth_place!,
        gender_id: genderId(values.natural_person_gender!),
        nationality_id: values.natural_person_nationality!,
      };
    } else {
      legalPersonParams = {
        registration_date: moment(
          values.legal_person_registration_date!
        ).format(FORMAT_DATE_TIME_4),
        registration_number: String(values.legal_person_registration_number!),
        contract_date: moment(values.legal_person_contract_date!).format(
          FORMAT_DATE_TIME_4
        ),
        main_activity: values.legal_person_main_activity!,
      };
    }

    let legal_representative_cuit_number = "";
    let legalRepresentativeParams: ILegalRepresentativeParams;
    if (values.legal_representative_yes_or_no) {
      if (typeof values.cuit_cuil === "string") {
        legal_representative_cuit_number = values.cuit_cuil;
      } else {
        legal_representative_cuit_number = values.cuit_cuil.value;
      }

      legalRepresentativeParams = {
        cuit: legal_representative_cuit_number!,
        name_surname: values.legal_representative_name_surname!,
        home_street: values.legal_representative_home!,
        home_number: String(values.legal_representative_number!),
        floor: values.legal_representative_floor!,
        department: values.legal_representative_department!,
        phone_number: String(values.legal_representative_phone!),
        birth_date: moment(values.legal_representative_birth_date!).format(
          FORMAT_DATE_TIME_4
        ),
        birth_place: values.legal_representative_birth_place!,
        email: values.legal_representative_email!,
        province_id: values.legal_representative_province!,
        postal_code_id: Number(values.legal_representative_postal_code!),
        gender_id: genderId(values.legal_representative_gender!),
        nationality_id: values.legal_representative_nationality!,
      };
    }

    await customRequest({
      mutation: Mutation.createEmission,
      variables: {
        input: emissionParams,
        pledgedCreditor: values.pledge_creditor_yes_or_no
          ? pledgedCreditorParams!
          : null,
        naturalPerson:
          personType === EnumsValues.PersonType.FISICA
            ? naturalPersonParams!
            : null,
        legalPerson:
          personType === EnumsValues.PersonType.JURIDICA
            ? legalPersonParams!
            : null,
        legalRepresentative: values.legal_representative_yes_or_no
          ? legalRepresentativeParams!
          : null,
        quotationId: null,
        renovation_id: renovationData ? renovationData.id : null,
        foreign: true
      },
    })
      .then((response: any) => {
        setErrorResponse({
          status_code: 0,
          message: response.error_notice,
        });
        const title =
          formType === EnumsValues.formType.directEmissionForeign
            ? "Emisión creada correctamente"
            : "Renovación creada correctamente";

        autoClosableModal(title, response.id_policy);

        saveImages();
      })
      .catch((error: any) => {
        setErrorResponse(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getInitialProducer = useCallback(() => {
    if (
      user &&
      user.producers &&
      user.producers.length === 1 &&
      formType === EnumsValues.formType.directEmissionForeign
    ) {
      return user.producers[0].id_liderar;
    } else {
      return (
        renovationData?.producer_id_liderar || emission?.producer.id_liderar
      );
    }
  }, [user, formType, renovationData, emission]);

  const readOnlyOr =
    formType === EnumsValues.formType.directEmissionReadOnly ||
    formType === EnumsValues.formType.directEmissionHistoricalReadOnly ||
    formType === EnumsValues.formType.renowedQuotesListReadOnly;

  return (
    useCheckAuthority(routeProps) || (
      <div className="renovationFormPage">
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          wrapperCol={{ span: 24 }}
        >
          <InformationForm
           className="information-form-patente-extranjera"
            user={user!}
            initialProducer={getInitialProducer()}
            isRenovation={!!renovationData}
            form={form}
            setSelectedProducer={setSelectedProducer}
            selectedProducer={selectedProducer}
            idsLiderarForMotorcycles={idsLiderarForMotorcycles}
            selectedVehicleType={selectedVehicleType}
            readOnlyOr={readOnlyOr}
          />
          {formType && (
            <ClientInformationForm
              fiscalConditionOptions={fiscalConditionOptions}
              form={form}
              cuit={
                formType === EnumsValues.formType.renovation ||
                formType === EnumsValues.formType.renowedQuotesListReadOnly
                  ? renovationData?.policy?.asegurado[0].cuit
                  : emission?.cuit
              }
              setPersonType={setPersonType}
              isRenovation={!!renovationData}
              setFiscalCondition={setFiscalCondition}
              readOnlyOr={readOnlyOr}
            />
          )}
          <GeneralDataForm
            fuelOptions={fuelOptions}
            vehicleBrand={vehicleBrand}
            brandOptions={brandOptions}
            form={form}
            getBrandModel={getBrandModel}
            vehicleModelOptions={vehicleModelOptions}
            vehicleModel={vehicleModel}
            getYearsByBrandAndModel={getYearsByBrandAndModel}
            yearOptions={yearOptions}
            yearList={yearList}
            getBrandModelByBrandModelYear={getBrandModelByBrandModelYear}
            vehicleVersionOptions={vehicleVersionOptions}
            vehicleVersionList={vehicleVersionList}
            clearOnChangeBrand={clearOnChangeBrand}
            clearOnChangeModel={clearOnChangeModel}
            clearOnChangeYear={clearOnChangeYear}
            getVehicleType={getVehicleType}
            vehicleTypeOptions={vehicleTypeOptions}
            vehicleTypes={vehicleTypes}
            destinyOptions={destinyOptions}
            destinos={destinos}
            clearOnChangeVersion={clearOnChangeVersion}
            clearOnChangeVehicleType={clearOnChangeVehicleType}
            getDestinos={getDestinos}
            bodyWorkOptions={bodyWorkOptions}
            coveragePeriedOptions={coveragePeriedOptions}
            coverageTypeOptions={coverageTypeOptions}
            renovationData={renovationData}
            isRenovation={!!renovationData}
            adjustmentRateOptions={adjustmentRateOptions}
            adjustmentRates={adjustmentRates}
            setAmountOutOfRange={setAmountOutOfRange}
            setAccesoriesError={setAccesoriesError}
            amountOutOfRange={amountOutOfRange}
            accesoriesError={accesoriesError}
            getFuelTypes={getFuelTypes}
            getBodyworkTypes={getBodyworkTypes}
            idsLiderarForMotorcycles={idsLiderarForMotorcycles}
            selectedProducer={selectedProducer}
            fuelTypes={fuelTypes}
            bodyworkTypes={bodyworkTypes}
            setBillingCode={setBillingCode}
            formType={formType}
            emission={emission}
            quotation={quotation}
            setSelectedVehicleType={setSelectedVehicleType}
            setAdditionalHail={setAdditionalHail}
            additionalHail={additionalHail}
            getCoverageTypes={getCoverageTypes}
            readOnlyOr={readOnlyOr}
            setRenovationBlocked={setRenovationBlocked}
          />
          <UserInformationForm
            paymentOptions={paymentOptions}
            form={form}
            provinceOptionsId={provinceOptionsId}
            renovationData={renovationData}
            provinces={provinces}
            readOnlyOr={readOnlyOr}
            paymentInstallmentTypesOptions={paymentInstallmentTypeOptions}
          />
          <NaturalPersonForm
                genderOptions={genderOptions}
                minBornTime={minBornTime}
                nationalityOptions={nationalityOptions}
                isRenovation={!!renovationData}
                readOnlyOr={readOnlyOr}
              />
          {form.getFieldsValue([formNames.clientInformation.CUIT_CUIL.name]) &&
            personType === EnumsValues.PersonType.JURIDICA && (
              <LegalPersonForm readOnlyOr={readOnlyOr} />
            )}
          <VehicleDataForm
            isRenovation={!!renovationData}
            readOnlyOr={readOnlyOr}
          />
          <VehiclePicture
            patent={
              renovationData?.policy?.vehiculo[0].patente || emission?.patent
            }
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
          />
          <PledgeCreditorDataForm
            form={form}
            provinceOptions={provinceOptions}
            formType={formType}
            emission={emission}
            readOnlyOr={readOnlyOr}
          />
          <LegalRepresentativeForm
            form={form}
            genderOptions={genderOptions}
            provinceOptions={provinceOptions}
            nationalityOptions={nationalityOptions}
            gendersTypes={gendersTypes}
            minBornTime={minBornTime}
            isReadOnlyForm={emissionReadOnly}
            formType={formType}
            emission={emission}
            readOnlyOr={readOnlyOr}
          />
          <QuoteForm
            form={form}
            emissionResponse={errorResponse}
            readOnlyOr={readOnlyOr}
          />
          <FormButtons
            isLoading={isLoading}
            amountOutOfRange={amountOutOfRange}
            accesoriesError={accesoriesError}
            emissionReadOnly={emissionReadOnly}
            formType={formType}
            quotationId={quotationId}
            confirmModal={() =>
              ModalConfirm({
                onOk: () => form.submit(),
                title: "Atención",
                content:
                  "Al emitir la póliza, se generará una Póliza de Vida asociada a la de auto, solicite conformidad al asegurado y seleccione Confirmar",
              })
            }
            onFinish={onFinish}
            fiscalCondition={fiscalCondition}
            renovationBlocked={renovationBlocked}
          />
        </Form>
      </div>
    )
  );
};

export default RenovationForeignFormPage;
