export const TOWING_SERVICE = `
query towing_service($input: TowingServiceRequestInput!) {
  towing_service(input:$input) {
    __typename
    ... on TowingService {
      resp
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const TOWING_SERVICE_OPTIONS = `
query getTowingServiceOptions($input: String!) {
  getTowingServiceOptions(input:$input) {
    __typename
    ...on TowingServiceOption {
      code
      description
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;